body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: "Poppins", sans-serif !important; 
 }
 h1, h2, h3, h4, h5, h6{
  font-family:  "Poppins", sans-serif !important;  
 }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
