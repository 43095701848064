.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.reactPlayer{
  width: 615px !important;
  max-width: 100% !important;
  height: 350px !important;
  margin: 0px auto; 
}
.makeStyles-divcolor-11 .makeStyles-container1-3{
  min-height: 700px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 1200px) {
.reactPlayer{
  height: 306px !important;
  padding-left: 40px;
}
}
@media only screen and (max-width: 958px) {
  .reactPlayer{
    height: 306px !important;
    padding-left: 0px;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  }

  @media only screen and (max-width: 500px) {
    .reactPlayer{
      height: 284px !important;
    }
    }
  @media only screen and (max-width: 420px) {
    .reactPlayer{
      height: 230px !important;
    }
    }
  @media only screen and (max-width: 380px) {
    .reactPlayer{
      height: 190px !important;
    }
    }     
